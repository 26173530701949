<template>
    <div>
        <van-nav-bar
        title="我的消费事项"
        left-arrow
        @click-left="onClickLeft"
        />
        <div style="padding:20px">
          <van-pull-refresh v-model="isDownLoading" @refresh="onRefresh">
          <van-empty description="还没有数据呢" v-if="isEmpty"/>
          <van-list
              v-else
              v-model="isUpLoading"
              :finished="finished"
              finished-text="没有更多了"
              :offset=offset
              @load="onLoad"
              >
              <div v-for="(item) in list" :key="item.id" class="list-item">
                <img :src=item.avatar alt="">
                <div class="costBox">
                   <p><span style="font-size: 16px;">{{item.consumptionType===2?'提货消费':'储值消费'}}</span><span class="grayword" style="font-size: 16px;">{{item.consumptionTime}}</span></p>
                   <!-- <p><span  class="grayword">{{item.consumptionType | consumptionTypeFormat}}</span><span>{{item.consumptionAmount}}</span></p> -->
                   <p><span >金额:{{item.consumptionAmount}}</span></p>
                   <p v-if="item.consumptionType==2"><span >商品:{{item.applicableProduct}}</span></p>
                   <p v-if="item.consumptionType==2"><span >数量:{{item.consumptionCnt}}</span></p>
                   <p><span >备注:{{item.consumptionMatters}}</span></p>
                  
                </div>
              </div>
          </van-list>
          </van-pull-refresh>
       </div>
    </div>
</template>
<script>
import {consumptionRecord} from "@/api/base";
import func from '@/lib/commonfun';
import { Notify } from 'vant';
export default {
  name: 'mycostrecord',
  data(){
    return {
        isUpLoading:false,//上拉加载
        isDownLoading:false,//下拉刷新
        offset:100,//滚动条与底部距离小于 offset 时触发load事件
        finished:false,
        listParams:{
          pageNo:0,
          pageSize:10,
          cardId:1
        },
        list:[
            // {
            //   "consumptionRecordId": 1,
            //     "cardId": 1,
            //     "consumptionType": 1, // 消费类型(0:储值, 1:消费, 2:提货)
            //     "consumptionMatters": "买一碗",  // 消费描述
            //     "consumptionTime": "2023-01-04 21:00:00", // 消费时间
            //     "consumptionAmount": 10.0100, // 消费金额
            //     "consumptionCnt": 1, // 消费数量
            //     "createTime": 1704342950000
            // },
        ],
        isEmpty:false
    }
  },
  mounted(){
    // this.listParams.cardId=this.$route.params.cardId;
    // console.log(this.listParams.cardId)
  },
  filters:{
    consumptionTypeFormat(val){
         let list = {
            0:'存储',
            1:'消费',
            2:'提货',
         }
         return list[val]
    }
  },
  methods:{
    cellBind(e){
        console.log(e)
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    onLoad(){
      if(this.isDownLoading){return}
      if(!this.finished){
        this.listParams.pageNo++;
        this.isUpLoading=true;
        this.getList(this.listParams)
      }
    },
    getList(params){
      consumptionRecord(params).then((res)=>{
        this.isDownLoading = false;
        this.isUpLoading=false;
        if(res.code==0){
          if(res.data && res.data.list){
            let list= res.data.list;
            if(list.length==0 && params.pageNo==1){
              this.isEmpty=true;
              this.finished=true;
              return;
            }
            list.map((item)=>{
               let avatar=sessionStorage.getItem('avatar')
               item.avatar=avatar?avatar:require('../../assets/imgs/anonymous.png')
               item.consumptionTime=func.timestampToYMD(item.consumptionTime);
               return item;
            })
            this.list= [...this.list, ...list]
            if(res.data.list.length<10){
              this.finished=true;
            }
          }else{
            this.finished=true;
          }
        }else{
          this.finished=true
          Notify({ type: 'warning', message: res.msg });
          // Toast.fail(res.msg);
        }
      },(err)=>{
        this.isDownLoading = false;
        this.isUpLoading=false;
        Notify({ type: 'warning', message: err.response.data.message});
      })
    },
    onRefresh() {
        this.finished = false;
        this.listParams.pageNo=1;
        this.list = []
        this.isDownLoading = true;
        this.getList(this.listParams);
    },
  }
}
</script>
<style lang="scss"  scoped>
.van-list{
  min-height: 100vh;
}
 .list-item{
    padding:20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    background: #fff;
    .costBox{
        flex: 1;
        p{
          line-height:30px;
          span:nth-child(1){
            display: inline-block;
            width:120px;
            font-size:14px;
          }
        }
    }
    img{
        width:40px;
        height:40px;
        border-radius: 50%;  
        margin-right:20px;
        margin-top:20px;
    }
 }
</style>